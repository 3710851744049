import React from 'react'
import { Tag, Progress, Tooltip, Popconfirm, Modal, Flex } from 'antd'
import { Link } from 'react-router-dom'
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  StopOutlined,
  CheckCircleOutlined,
  UndoOutlined,
  TruckOutlined,
} from '@ant-design/icons'
import { analysesRootURL, ordersRootURL } from '../../../Common/constants'
import { formatDate } from '../../../Common/services/helpers/helpers'
import {
  getWorkflowTagColor,
  getPriorityTagColor,
  getTypeTagColor,
  getStatusTagColor,
} from '../../../Common/styleHelpers'
import { supportSystemTicketLink } from '../../constants'
import styles from '../EditableTable.module.css'
import { isAnalysisDeliverable } from '../../utils'
import CopyMessageAction from '../Component/CopyMessageAction'

const getDefaultColumns = (
  dataSource,
  handleVisibilityChange,
  handleStatusChange,
  handleDelivered,
  orderId
) => {
  return [
    {
      title: 'Case',
      dataIndex: 'case_id',
      key: 'case_id',
      sorter: true,
      render: (_, record) => (
        <Link to={`${analysesRootURL}/case/${record.id}`}>{record.case_id}</Link>
      ),
    },
    !orderId && {
      title: 'Ticket',
      key: 'ticket_id',
      dataIndex: 'ticket_id',
      width: 90,
      render: (ticket) => <a href={supportSystemTicketLink(ticket)}>{ticket}</a>,
      sorter: true,
    },
    !orderId && {
      title: 'Order',
      key: 'order_id',
      dataIndex: 'order_id',
      width: 90,
      render: (id) => <Link to={`${ordersRootURL}/order/${id}`}>{id}</Link>,
    },
    !orderId && {
      title: 'Workflow',
      dataIndex: 'workflow',
      key: 'workflow',
      width: 130,
      render: (type: any) =>
        type && (
          <Tag color={getWorkflowTagColor(type)} key={type}>
            {type.toUpperCase()}
          </Tag>
        ),
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (type: any) => {
        return (
          type && (
            <Tag color={getPriorityTagColor(type)} key={type}>
              {type.toUpperCase()}
            </Tag>
          )
        )
      },
      filters: [
        {
          text: 'Low',
          value: 'low',
        },
        {
          text: 'Normal',
          value: 'normal',
        },
        {
          text: 'High',
          value: 'high',
        },
        {
          text: 'Express',
          value: 'express',
        },
      ],
    },
    {
      title: 'Started',
      dataIndex: 'started_at',
      key: 'started_at',
      render: (date: string) => formatDate(date),
      sorter: true,
    },
    {
      filters: [
        {
          text: 'WES',
          value: 'wes',
        },
        {
          text: 'WGS',
          value: 'wgs',
        },
        {
          text: 'TGS',
          value: 'tgs',
        },
        {
          text: 'OTHER',
          value: 'other',
        },
      ],
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 90,
      render: (type: any) =>
        type && (
          <Tag color={getTypeTagColor(type)} key={type}>
            {type.toUpperCase()}
          </Tag>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, item) => {
        if (status === 'running') {
          return <Progress percent={Math.floor(item.progress * 100)} />
        } else if (status === 'failed') {
          if (item.failed_job) {
            return (
              <Tooltip placement="bottom" title={item.failed_job.name}>
                <Tag color={getStatusTagColor(status)} key={status}>
                  {status.toUpperCase()}
                </Tag>
              </Tooltip>
            )
          } else {
            return (
              <Tag color={getStatusTagColor(status)} key={status}>
                {status.toUpperCase()}
              </Tag>
            )
          }
        } else {
          return (
            status && (
              <Tag color={getStatusTagColor(status)} key={status}>
                {status.toUpperCase()}
              </Tag>
            )
          )
        }
      },
      filters: [
        {
          text: 'Cancelled',
          value: 'cancelled',
        },
        {
          text: 'Completed',
          value: 'completed',
        },
        {
          text: 'Error',
          value: 'error',
        },
        {
          text: 'Failed',
          value: 'failed',
        },
        {
          text: 'Pending',
          value: 'pending',
        },
        {
          text: 'QC',
          value: 'qc',
        },
        {
          text: 'Running',
          value: 'running',
        },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      sorter: true,
    },
    {
      title: 'Uploaded',
      dataIndex: 'uploaded_at',
      key: 'uploaded_at',
      render: (date: string) => formatDate(date),
      sorter: true,
    },
    {
      filters: [
        {
          text: 'Delivered',
          value: 'true',
        },
        {
          text: 'Not delivered',
          value: 'false',
        },
      ],
      filterMultiple: false,
      title: 'Delivered By',
      dataIndex: 'delivered_by',
      key: 'is_delivered',
      render: (_, record) => {
        const isDelivered = record && record.is_delivered
        if (!isDelivered) {
          return <></>
        }

        return (
          <Flex vertical>
            <div>{record?.delivered_by || ''}</div>
            <div>{formatDate(record?.delivered_date)}</div>
          </Flex>
        )
      },
    },
    {
      filters: [
        {
          text: 'Commented',
          value: 'true',
        },
        {
          text: 'Uncommented',
          value: 'false',
        },
      ],
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      editable: true,
    },
    {
      title: 'Action',
      key: 'action',
      ellipsis: true,
      width: 175,
      render: (record) => {
        return dataSource.length >= 1 ? (
          <div className={styles.actionBox}>
            <Popconfirm
              title={`Are you sure you want to ${record?.is_visible ? 'hide' : 'unhide'}?`}
              placement="topRight"
              onConfirm={() => handleVisibilityChange(record.id, record.is_visible)}
            >
              {record?.is_visible ? (
                <div className={styles.actionIcons}>
                  <Tooltip placement="bottom" title="Mark as hidden">
                    <EyeInvisibleOutlined />
                  </Tooltip>
                </div>
              ) : (
                <div className={styles.actionIcons}>
                  <Tooltip placement="bottom" title="Mark as unhidden">
                    <EyeOutlined />
                  </Tooltip>
                </div>
              )}
            </Popconfirm>
            {isAnalysisDeliverable(record) ? <CopyMessageAction record={record} /> : null}
            {record.status === 'error' ? (
              <>
                <Popconfirm
                  title="Are you sure you want to set status to Failed?"
                  placement="topRight"
                  onConfirm={() => handleStatusChange(record.id, 'failed')}
                >
                  <div className={styles.actionIcons}>
                    <Tooltip placement="bottom" title="Mark as Failed">
                      <StopOutlined />
                    </Tooltip>
                  </div>
                </Popconfirm>
                <Popconfirm
                  title="Are you sure you want to set status to Complete?"
                  placement="topRight"
                  onConfirm={() => handleStatusChange(record.id, 'completed')}
                >
                  <div className={styles.actionIcons}>
                    <Tooltip placement="bottom" title="Mark as Complete">
                      <CheckCircleOutlined />
                    </Tooltip>
                  </div>
                </Popconfirm>
              </>
            ) : null}
            {record.status === 'failed' ? (
              <>
                <Popconfirm
                  title="Are you sure you want to set status to Complete?"
                  placement="topRight"
                  onConfirm={() => handleStatusChange(record.id, 'completed')}
                >
                  <div className={styles.actionIcons}>
                    <Tooltip placement="bottom" title="Mark as Complete">
                      <CheckCircleOutlined />
                    </Tooltip>
                  </div>
                </Popconfirm>
              </>
            ) : null}
            {record.status === 'pending' ||
            record.status === 'running' ||
            record.status === 'completed' ? (
              <>
                <Popconfirm
                  title="Are you sure you want to set status to Failed?"
                  placement="topRight"
                  onConfirm={() => handleStatusChange(record.id, 'failed')}
                >
                  <div className={styles.actionIcons}>
                    <Tooltip placement="bottom" title="Mark as Failed">
                      <StopOutlined />
                    </Tooltip>
                  </div>
                </Popconfirm>
              </>
            ) : null}
            {record.is_delivered ? (
              <Popconfirm
                title="Are you sure you want to mark it as not delivered?"
                placement="topRight"
                onConfirm={() => handleDelivered(record.id, false)}
              >
                <div className={styles.actionIcons}>
                  <Tooltip placement="bottom" title="Mark as not delivered">
                    <UndoOutlined />
                  </Tooltip>
                </div>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Are you sure you want to mark as delivered?"
                placement="topRight"
                onConfirm={
                  record.uploaded_at
                    ? () => handleDelivered(record.id, true)
                    : () =>
                        Modal.confirm({
                          title: 'Analyses not uploaded',
                          content: 'Are you sure you want to deliver a non-uploaded analysis?',
                          onOk() {
                            handleDelivered(record.id, true)
                          },
                        })
                }
              >
                <div className={styles.actionIcons}>
                  <Tooltip placement="bottom" title="Mark as delivered">
                    <TruckOutlined />
                  </Tooltip>
                </div>
              </Popconfirm>
            )}
          </div>
        ) : null
      },
    },
  ].filter(Boolean)
}
export const AnalysesTableColumns = (
  dataSource,
  handleVisibilityChange,
  handleStatusChange,
  handleSave,
  handleDelivered,
  orderId
) => {
  const defaultColumns: any[] = getDefaultColumns(
    dataSource,
    handleVisibilityChange,
    handleStatusChange,
    handleDelivered,
    orderId
  )
  return defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    }
  })
}
